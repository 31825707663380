import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { CellserviceService } from '../cellService/cellservice.service';

@Component({
  selector: 'app-field-view',
  templateUrl: './field-view.component.html',
  styleUrls: ['./field-view.component.scss'],
})
export class FieldViewComponent implements OnInit {
  @ViewChild('fieldDiv') myDiv: ElementRef;
  @Input() zoomFactor: number;
  @Input() baseSize: number;

  @Input() myData: any;

  constructor(private cellService: CellserviceService) { }

  ngOnInit() {}

  getMyCell(): any{
    return this.cellService.getCell(this.myData.x,this.myData.y);
  }

  getFlowRotationDeg(): String{
    if (this.getFlowSpeed() ===0){
      return '0deg';
    } else {
        let a = Math.round((Math.asin(this.getMyCell().vy/this.getFlowSpeed())/ Math.PI * 180)/5)*5;
        if ( this.getMyCell().vx < 0 ){
          a=180-a;
        }

        return a +'deg';
    }

    // if (this.getMyCell().vx ===0){
    //   return 0;
    // } else {
    //     let a = Math.round(Math.atan(this.getMyCell().vy/this.getMyCell().vx)/ Math.PI * 180);
    //     return a +'deg';
    // }
  }



  getFlowSpeed():number{
    let res: number = Math.sqrt(Math.pow(this.getMyCell().vx,2)+Math.pow(this.getMyCell().vy,2));
    return Math.round(res);
  }


  getAnimationDuration():string{
    if (this.getFlowSpeed() ===0){ return '0s'}

    return Math.round(0.5/((this.getFlowSpeed()/10000)/1000)) +'s';
  }
  isBackgroundVisisble(): boolean{
    return this.getMyCell().moleculeCount >0;
  }

  fieldDblClicked(){
    console.log(this.getMyCell());
    let data:any = {
      CommandType: 1,
      xCooridnate: this.getMyCell().x,
      yCooridnate: this.getMyCell().y,
      Player: 0
    };
    this.cellService.commandCreateCellWall(data);
  }

  hasWestWall():boolean{
    return (this.getMyCell().fieldBitmask & 1) >0; // 1: West Wall
  }
  hasSouthWall():boolean{
    return (this.getMyCell().fieldBitmask & 2) >0; // 2: South Wall
  }
  hasEastWall():boolean{
    return (this.getMyCell().fieldBitmask & 4) >0; // 1: East Wall
  }
  hasNorthWall():boolean{
    return (this.getMyCell().fieldBitmask & 8) >0; // 1: North Wall
  }

  getOrganelleImageSrc(index:number):string{
    switch (this.getOrganelle(index)){

      case 1: {
        return '../../../assets/fountain.png';
        break;
      }

      default: {
        return '../../../assets/unknown.png';
        break;
      }
    }

  }

  getOrganelle(index:number):number{
    if(this.getMyCell().organelles){
      return this.getMyCell().organelles[index];
    } else { return 0;}
  }

}
