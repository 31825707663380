import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fieldpicker',
  templateUrl: './fieldpicker.component.html',
  styleUrls: ['./fieldpicker.component.scss'],
})
export class FieldpickerComponent implements OnInit {
  @Input() fieldCooridnates: any;
  constructor() { }

  ngOnInit() {}

}
