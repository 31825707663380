import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CellserviceService {
  //apiUrl: string = 'http://192.168.178.43:3632';
  //apiUrl: string = 'http://localhost:3632';
  apiUrl: string = 'https://r1aojyfdl3.execute-api.eu-central-1.amazonaws.com/dev';


  public data: any[][] = [[]];
  private mapViews:any[]; // {}

   constructor(private http: HttpClient) { }

   getCell(x:number, y:number): any{
     if (this.data[x] && this.data[x][y]){
       return this.data[x][y]
     } else {
       return {moleculeCount: 0,
                ​​​moleculeMass: 0,
                ​​​vx: 0,
                ​​​vy: 0,
                ​​​x: y,
                ​​​y: x,
                unknown: true}
     }
   }

   commandCreateCellWall(data: any){
     const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type':  'application/json'
       })};

      let API_URL = `${this.apiUrl}/command`;
      console.log('commandCreateCellWall '+ API_URL);
      let res : any = data =  this.http.post(API_URL, data, httpOptions).pipe(
       catchError(this.error)
     );

     res.subscribe(data =>{
       console.log(this.data);
     });
 }
   // Update
   getMapSegment(data:any) {
     //console.log(JSON.stringify(data));
     const httpOptions = {

       headers: new HttpHeaders({
         'Content-Type':  'application/json'
       })};

      let API_URL = `${this.apiUrl}/map`;

      //console.log('GetMapSegment '+ API_URL);
      let res : any = data =  this.http.post(API_URL, data, httpOptions).pipe(
       catchError(this.error)
     );
     res.subscribe(data =>{
       data.Res.forEach(obj =>{
         //console.log(obj);
         if (!this.data[obj.x]){
           this.data[obj.x]=[];
         }
         this.data[obj.x][obj.y] = obj;
       });
       //console.log(this.data);

     });
   }
   // Handle Errors
     error(error: HttpErrorResponse) {
       let errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         errorMessage = error.error.message;
       } else {
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
     }

     getSpeed(vx:number, vy:number):number{
       return Math.sqrt(vx*vx+vy*vy);
     }

}
