import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MapViewComponent } from './map-view/map-view.component';
import { FieldViewComponent } from './field-view/field-view.component';
import { FieldpickerComponent} from './fieldpicker/fieldpicker.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


export { CellserviceService } from './cellService/cellservice.service';

@NgModule({
  declarations: [MapViewComponent, FieldViewComponent, FieldpickerComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DragDropModule
  ],
  exports: [MapViewComponent, FieldViewComponent, FieldpickerComponent]
})
export class CellClientMapModule { };
