/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:f6fd552b-8fe6-4ca8-bcdc-d18cc789d415",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_qur0YyLsU",
    "aws_user_pools_web_client_id": "1seifn419nvjcsf6dri2envg6m",
    "oauth": {}
};


export default awsmobile;
