import { Component, AfterViewInit, OnInit,ElementRef, ViewChild, Input } from '@angular/core';
import { interval } from 'rxjs';
import { CellserviceService } from '../cellService/cellservice.service';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit , AfterViewInit{
  vzoomFactor:number=1.0;
  focusCoordinatesX: number;
  focusCoordinatesY: number;
  fieldBaseSize: number = 50;

  @ViewChild('mapFrame') mapFrame: ElementRef;
  @ViewChild('dragArea') dragArea: ElementRef;
  @ViewChild('mapArea') mapArea: ElementRef;

  fieldMatrix:any[];
  isInitialized: boolean = false;
  width: number =0;
  height: number =0;


  constructor(private cellService: CellserviceService) {
    interval(1000).subscribe(x => {
        this.cellService.getMapSegment(this.getMyMapCorners());
    });
  }

  onMouseWheel(evt) {
      //console.log('Wheel event: ', evt);
      this.zoom(this.vzoomFactor-0.05*evt.deltaY,
        evt.clientX-this.getPosition(this.mapFrame.nativeElement).left,
        evt.clientY-this.getPosition(this.mapFrame.nativeElement).top);
  }

  zoom(newZoomFactor:number, centerCoordinateX:number, centerCoordinateY: number){
    //console.log(this.fieldMatrix.length);
    if (this.fieldMatrix.length <600 || newZoomFactor > this.vzoomFactor){
      let offsetLeft: number=this.mapArea.nativeElement.getBoundingClientRect().x-this.dragArea.nativeElement.getBoundingClientRect().x;
      let offsetTop: number= this.mapArea.nativeElement.getBoundingClientRect().y- this.dragArea.nativeElement.getBoundingClientRect().y;
      let dragOffsetLeft: number = this.dragArea.nativeElement.getBoundingClientRect().x - this.mapFrame.nativeElement.getBoundingClientRect().x;
      let dragOffsetTop: number = this.dragArea.nativeElement.getBoundingClientRect().y - this.mapFrame.nativeElement.getBoundingClientRect().y;
      let trxOffset = (centerCoordinateX-dragOffsetLeft)-(centerCoordinateX-dragOffsetLeft-offsetLeft)/this.vzoomFactor*newZoomFactor;
      let tryOffset = (centerCoordinateY-dragOffsetTop)-(centerCoordinateY-dragOffsetTop-offsetTop)/this.vzoomFactor*newZoomFactor;
      this.vzoomFactor = newZoomFactor;

      let newtransform:string = "translate3d("+Math.round(trxOffset)+"px,"+Math.round(tryOffset)+"px,0)";
      // console.log(newtransform);
      // console.log(this.mapArea.nativeElement.style.transform);
      this.mapArea.nativeElement.style.transform=newtransform;
      this.updateFieldMatrix();
    }
  }



  initializeFocusCoordinates(x:number, y:number){
    if (!(this.focusCoordinatesX && this.focusCoordinatesY)){
      this.focusCoordinatesX = x;
      this.focusCoordinatesY = y;
    }
  }

  onDragEnded(event) {
    this.updateFieldMatrix();
  }


  onMoved(event) {
    this.updateFieldMatrix();
    // let element = event.source.getRootElement();
    // let boundingClientRect = element.getBoundingClientRect();
    // let parentPosition = this.getPosition(element);
    // console.log('x: ' + (boundingClientRect.x - parentPosition.left), 'y: ' + (boundingClientRect.y - parentPosition.top));
  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while(el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }


  ngOnInit() {
    //this.fieldMatrix = this.getShownFields()
    console.log("onnginit");
    this.initializeFocusCoordinates(0,0);
  }
  ngAfterViewInit() {
    console.log("ngAfterViewInit");
    this.updateFieldMatrix();
  }

  update(){
    if (!this.isInitialized){

    }
    // console.log("MapView Updated")
    // console.log(this.mapFrame);
    // console.log(this.getPosition(this.mapArea.nativeElement));
    // console.log(this.mapArea.nativeElement.getBoundingClientRect());
    //console.log(this.cellService.data.Res);
    //this.myDiv.nativeElement.innerHTML = JSON.stringify(this.cellService.data.Res);
  }

  getMyMapCorners(): any {//{"BottomLeft":{"x":2,"y":4},"TopRight":{"x":4,"y":6}}
    let offsetLeft: number=this.mapArea.nativeElement.getBoundingClientRect().x-this.dragArea.nativeElement.getBoundingClientRect().x;
    let offsetTop: number= this.mapArea.nativeElement.getBoundingClientRect().y- this.dragArea.nativeElement.getBoundingClientRect().y;
    let dragOffsetLeft: number = this.dragArea.nativeElement.getBoundingClientRect().x - this.mapFrame.nativeElement.getBoundingClientRect().x;
    let dragOffsetTop: number = this.dragArea.nativeElement.getBoundingClientRect().y - this.mapFrame.nativeElement.getBoundingClientRect().y;
    let topLeftX: number = -dragOffsetLeft-offsetLeft;
    let topLeftY: number = -dragOffsetTop-offsetTop;
    let bottomRightX: number = this.mapFrame.nativeElement.offsetWidth-dragOffsetLeft-offsetLeft;
    let bottomRightY: number = this.mapFrame.nativeElement.offsetHeight-dragOffsetTop-offsetTop;
    let fieldSize:number = this.fieldBaseSize*this.vzoomFactor;
    return {"BottomLeft": {"x":Math.round(topLeftX / fieldSize)-1,
                           "y":Math.round(topLeftY / fieldSize)-1},
            "TopRight":   {"x":Math.round(bottomRightX/fieldSize),
                           "y":Math.round(bottomRightY/fieldSize)}};
  }


  updateFieldMatrix() {
    let corners: any = this.getMyMapCorners();
    this.fieldMatrix = this.getShownFields(
      corners.TopRight.x,
      corners.TopRight.y,
      corners.BottomLeft.x,
      corners.BottomLeft.y
    );
  }

  getShownFields(TopRightX:number, // Diese Feldkoorinate witd bei Pixel 0,0 angezeigt
                TopRightY:number,
                BottomLeftX: number,
                BottomLeftY: number):any[]{

    let Res: any[] = [];
    for (let xx: number = BottomLeftX; xx<= TopRightX;xx++){
      for (let yy: number = BottomLeftY; yy<= TopRightY;yy++){
        var o:any = new Object;
        o.x = xx;
        o.y = yy;
        Res.push(o);
      }
    }
    return Res;
  }



}
